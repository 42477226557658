<template>
	<div class="perfectInfo">
		<van-nav-bar
			title="完善賬號信息"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		/>
		
		<div class="tip qjc-fts-26">
			為了更好地保障您的權益，請認真填寫您的賬號信息
		</div>
		
		<div class="form qjc-agent-inputs">
			<van-field
				v-model="name"
				label="姓名"
				placeholder="請輸入姓名"
			></van-field>
			
			<div class="sex qjc-clearfix">
				<label class="qjc-fl">性别</label>
				
				<van-radio-group v-model="sex" checked-color="#6883FB" class="qjc-fl">
				  <van-radio name="1">男</van-radio>
				  <van-radio name="2">女</van-radio>
				</van-radio-group>
			</div>
			
			<van-field
				v-model="birthShow"
				label="出生日期"
				placeholder="請選擇出生日期"
				readonly
				class="birth"
				:right-icon="require('@/assets/images/agent_birth.png')"
				@click="selBirth = true"
			></van-field>
			
			<van-button @click="submit" class="login-btn qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
				<span class="qjc-vertical-m">提交</span>
				<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
			</van-button>
			
		</div>
		
		<!-- 出生日期选择 -->
		<van-popup
			v-model="selBirth"
			position="bottom"
		>
			<van-datetime-picker
			  v-model="defaultBirth"
			  type="date"
			  title="选择年月日"
			  :min-date="minDate"
			  :max-date="maxDate"
			  @cancel="selBirth = false"
			  @confirm="checkBirth"
			/>
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, RadioGroup, Radio, Button, DatetimePicker, Popup, Toast } from 'vant'
	
	Vue.use(NavBar)
	   .use(Field)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(Button)
	   .use(DatetimePicker)
	   .use(Popup)
	   .use(Toast);
	   
	import { agentLogin } from '@/js/axios'
	
	export default{
		name: 'perfectInfo',
		data() {
			return {
				name: '',
				sex: '',
				birth: '',
				
				selBirth: false,
				defaultBirth: '',// 日期默认展示
				minDate: '',
				maxDate: '',
				
				errField: ''
			}
		},
		computed: {
			birthShow() {
				if(this.birth){
					var birth = new Date(this.birth*1000),
						y = birth.getFullYear(),
						m = birth.getMonth()+1,
						d = birth.getDate();
					return y + '/' + m + '/' + d;
				}else{
					return '';
				}
			}
		},
		mounted() {
			// 日期
			// 默认20年前
			this.defaultBirth = this.agoDate(-20);
			// 最小200年前
			this.minDate = this.agoDate(-200);
			// 最大当前
			this.maxDate = this.agoDate(0)
		},
		methods: {
			// 提交
			submit() {
				if(!this.name.trim()){
					this.name = '';
					Toast.fail('請輸入姓名');
				}else if(this.sex != 1 && this.sex != 2){
					Toast.fail('請選擇您的性別');
				}else if(!this.birth){
					Toast.fail('請選擇您的出生日期');
				}else{
					let data = {
						name: this.name,
						sex: this.sex,
						birth: this.birth
					}
					
					agentLogin(Object.assign(data, JSON.parse(sessionStorage.getItem('agentRegistInfo')))).then(res => {
						// console.log(res);
						if(res.code == 200){
							this.$store.dispatch('agentLogin',res.data.access_token).then(() => {
								sessionStorage.removeItem('agentRegistInfo');
								Toast.fail('提交成功');
								
								let path = this.$route.query.toUrl?this.$route.query.toUrl:'/agent';
								this.$router.push(path);
							})
							.catch(() => {
								
							});
							
						}else{
							Toast.fail(res.msg);
						}
					})
				}
			},
			
			checkBirth(val) {
				this.birth = val.getTime()/1000;
				this.selBirth = false;
			},
			
			// 距离当前日期多少年 
			agoDate(year) {
				var now = new Date(),
					y = now.getFullYear(),
					m = now.getMonth()+1,
					d = now.getDate();
				
				return new Date((y+year)+'/'+m+'/'+d);
			}
		}
	}
</script>

<style scoped>
	.form >>> .van-field__label,
	.form .sex label{
		width: 1.64rem;
		text-align: left;
		color: #3B4549;
		font-size: 0.32rem;
		padding-left: 0.12rem;
	}
	.form .birth >>> .van-icon,
	.form .birth >>> .van-image{
		width: 0.4rem;
		height: 0.4rem;
	}
	.form .birth >>> .van-field__right-icon{
		padding-right: 0.24rem;
	}
	.van-picker >>> .van-picker__cancel, 
	.van-picker >>> .van-picker__confirm{
		color: #6681FA;
	}
</style>
<style lang="scss" scoped>
	.perfectInfo{
		padding-bottom: 0.4rem;
		
		.tip{
			line-height: 0.72rem;
			color: #8C9699;
			background-color: #EBEDF5;
		}
		
		.form{
			padding: 0 0.24rem;
			
			.van-field,
			.sex{
				margin-top: 0.32rem;
			}
			.sex{
				padding: 0.19rem 0;
				border-bottom: 0.01rem solid #E1E3EB;
				
				.van-radio-group{
					display: flex;
					padding-left: 0.12rem;
					font-size: 0.3rem;
					
					.van-radio:not(:last-child){
						margin-right: 1.24rem;
					}
				}
			}
			
			.login-btn{
				width: 3.24rem;
				height: 0.8rem;
				line-height: 1;
				border-radius: 0.08rem;
				margin: 1.11rem 0 0.18rem;
				padding: 0 0 0 0.2rem;
				
				img{
					width: 0.59rem;
					height: auto;
					margin: -0.1rem 0 0 0.11rem;
				}
			}
		}
	}
</style>
